@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.app-body {
  margin-left: 277px;
  padding: 24px 24px;
}

.ResponsiveMenu {
  display: none;
}

@media (max-width: 1500px) {
  .app-body {
    width: 79%;
    height: 400px;
    margin-left: 21%;
    padding: 20px;
  }
}

@media (max-width: 1300px) {
  .DesktopView {
    display: none;
  }

  .ResponsiveMenu {
    display: block;
  }

  .app-body {
    width: auto;
    height: 400px;
    margin-left: 0;
    padding: 20px;
  }
}

.Inner-Page-Side-Bar-Tabs .AccountCopyButton .MuiButtonBase-root {
  padding: 6px 16px !important;
}

.AccountCopyButton .MuiButtonBase-root {
  display: flex !important;
  align-items: center !important;
  border-radius: 8px !important;
  border: 1px solid #e8eaed !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  padding: 6px 16px !important;
  color: #0450e1;
}

.AccountCopyButton .MuiButtonBase-root svg {
  font-size: 20px;
  color: #0450e1;
  margin-right: 8px;
}

.alignRight {
  display: flex !important;
  justify-content: right !important;
}

.AccountDetailTableTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5c677d;
  text-transform: uppercase;
  padding: 0 16px;
  padding-top: 16px;
}

.AccountDetailTable {
  margin-bottom: 28px;
}

/* ==== Approve Modal === */
.ApproveAlertModal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #ff6200;
  background: #fff;
  padding: 16px;
  margin-bottom: 32px;
}

.ApproveAlertModal .ApproveAlertModalLeft {
  display: flex;
  align-items: center;
  color: #1b1b1f;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.ApproveAlertModal .ApproveAlertModalLeft svg {
  margin-right: 5px;
  color: #ff6200;
}

.ApproveButton .MuiButtonBase-root {
  background: #004fe0;
  border-radius: 100px;
  padding: 5px 14px !important;
  color: #fff;
  font-size: 14px !important;
  text-transform: initial !important;
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
}

.ApproveButton .MuiButtonBase-root:hover {
  background: #004fe0;
  color: #fff;
}

/* === Approve modal end === */

.emailAddressInputContainer
  .TextField
  .FieldContainer
  .MuiFormControl-root.MuiTextField-root
  .MuiFormHelperText-root {
  margin-left: 0px !important;
}
